import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from 'components/ScrollToTop';
import withAuth from 'components/Auth/withAuth';
import { Helmet } from 'react-helmet';

import Preapproval from 'pages/Preapproval';
import Verification from 'pages/Verification';
import PreapprovalSuccess from 'pages/Preapproval/PreapprovalSuccess';
import Overview from 'pages/Overview';
import Summary from 'pages/Summary';
import Tasks from 'pages/Tasks';
import NotFound from 'pages/NotFound';
import RegisterSuccess from 'pages/RegisterSuccess';
import { FB_PIXEL_ID, GTM_ID } from 'constants/Api';

const Routes = () => (
	<Router>
		<Helmet>
			<title>TapHomes Menyediakan Berbagai Solusi Cerdas Untuk Kepemilikan Rumah</title>
			<meta
				content="TapHomes membantu kamu untuk memiliki rumah idaman dengan layanan rent to own. Mulai miliki rumah impian kamu dengan mudah, cepat, aman dan terpercaya"
				name="description"
			/>
			<meta
				content="Rent to Own, Rumah Idaman, Cicil Rumah, Jual Beli Rumah, KPR, Rumah Milenial, Kepemilikan Rumah, Teknologi Properti, Rumah DP Murah"
				name="keywords"
			/>
			<script async src={`https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`} />
			<script>
				{`window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());
						gtag('config', '${GTM_ID}');
				`}
			</script>
			<script>
				{`!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${FB_PIXEL_ID}');
          fbq('track', 'PageView');
        `}
			</script>
			<noscript>
				{`<img height="1" src="https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1" style="display:none" width="1" />`}
			</noscript>
		</Helmet>
		<ScrollToTop />
		<Switch>
			<Route component={Preapproval} path="/preapproval" />
			<Route component={PreapprovalSuccess} path="/preapproval-success" />
			<Route component={withAuth(RegisterSuccess)} path="/register-success" />
			<Route component={withAuth(Overview)} path="/overview" />
			<Route component={withAuth(Summary)} path="/summary" />
			<Route component={withAuth(Tasks)} path="/tasks" />
			<Route component={withAuth(Verification)} path="/verification" />
			<Route component={NotFound} path="/" />
		</Switch>
	</Router>
);

export default Routes;
